





































import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})
export default class Email extends Vue {
  private email = ''
  private isError = false
  private otpError = false
  private userNotexist = false
  private isAuthToken = false
  private userSessionData:any = null

  private created (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
        // this.$router.push({ name: 'splash' })
      })
    }
    localStorage.setItem('isAuth', 'false')
    localStorage.setItem('testResult', '')
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  private submit (): void {
    if (!this.validEmail()) {
      this.isError = true
      return
    }
    this.isError = false
    // if (this.isAuthToken && this.userSessionData.organization_code !== null && this.userSessionData.organization_code !== '') {
    //   if ('email' in this.userSessionData) {
    //     if (this.userSessionData.email !== this.email) {
    //       this.$store.dispatch('auth/setDemographic', false)
    //     }
    //   } else {
    //     this.$store.dispatch('auth/setDemographic', false)
    //   }
    //   this.$store.dispatch('auth/setUpdatedOrganizationCode', this.userSessionData.organization_code)
    this.sentVerifyCode().then((response) => {
      if (response) {
        localStorage.setItem('newEmail', this.email)
        this.otpError = false
        this.$router.push({ name: 'email verify' })
      } else {
        localStorage.setItem('newEmail', '')
        this.otpError = true
      }
    })
    //   this.userNotexist = false
    // } else {
    //   this.userNotexist = true
    // }
  }

  private mounted (): void {
    this.email = this.$store.getters['auth/getEmail']
    this.userSessionData = this.$store.getters['auth/getUserSessionData']

    if (this.userSessionData != null) {
      if ('organization_code' in this.userSessionData) {
        if ('email' in this.userSessionData) {
          this.isAuthToken = true
        }
      }
    }
  }

  private sentVerifyCode (): Promise<boolean> {
    var data = {
      email: this.email, login_mode: true
    }
    return this.$store.dispatch('auth/emailOtpSent', data)
  }

  private validEmail (): boolean {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(this.email)
  }
}
