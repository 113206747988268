




































import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})
export default class Email extends Vue {
  private email = ''
  private organizationCode = ''
  private userSessionData:any = null
  private isError = false
  private isErrorOrganization = false
  private otpError = false
  private created (): void {
    localStorage.setItem('isAuth', 'false')
  }

  private submit (): void {
    this.$root.$emit('show-loader')
    if (!this.validEmail()) {
      this.isError = true
      this.$root.$emit('close-loader')
      return
    }
    if (this.organizationCode === '') {
      this.isErrorOrganization = true
      this.$root.$emit('close-loader')
      return
    }
    if (this.userSessionData !== null && 'email' in this.userSessionData) {
      this.$root.$emit('close-loader')
      if (this.userSessionData.email !== this.email) {
        this.$store.dispatch('auth/setDemographic', false)
        this.$root.$emit('close-loader')
      }
    } else {
      this.$store.dispatch('auth/setDemographic', false)
      this.$root.$emit('close-loader')
    }
    this.isError = false
    this.$root.$emit('show-loader')
    this.sentVerifyCode().then((response) => {
      if (response) {
        localStorage.setItem('newEmail', this.email)
        this.otpError = false
        this.$router.push('signup/verify')
        this.$root.$emit('close-loader')
      } else {
        localStorage.setItem('newEmail', '')
        this.otpError = true
        this.$root.$emit('close-loader')
      }
    })
  }

  private mounted (): void {
    this.email = this.$store.getters['auth/getEmail']
    this.organizationCode = this.$store.getters['auth/organizationCode']
    this.userSessionData = this.$store.getters['auth/getUserSessionData']

    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  private sentVerifyCode (): Promise<boolean> {
    var data = {
      email: this.email, login_mode: true
    }
    return this.$store.dispatch('auth/emailOtpSent', data)
  }

  private validEmail (): boolean {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(this.email)
  }
}
